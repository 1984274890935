
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























































































































































.phone__flag {
  flex-shrink: 0;
  width: 2rem;
  margin-right: 0.5rem;
}

.country-codes {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid $c-gray-lighter;

  ::v-deep .placeholder {
    justify-content: flex-end;
  }

  ::v-deep .options {
    width: 9rem;
  }
}

.country-codes-select {
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
